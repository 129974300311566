<template>
  <el-main>
    <el-button style="margin-bottom: 15px" size="small" type="primary" @click="$router.push('/service/beautyAddCardService')">+ 添加卡项</el-button>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="卡项名称：">
        <el-input v-model="ruleForm.card_item_name" size="small" placeholder="请输入卡项名称"></el-input>
      </el-form-item>
      <el-form-item label="是否上架：">
        <el-select size="small" v-model="ruleForm.status">
          <el-option v-for="item in statusList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getList(1)">搜索</el-button>
        <el-button size="small">导出</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="cardList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column align="center">
        <template #header>
          <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
        </template>
        <template v-slot="{ row }">
          <el-checkbox @change="serviceCheckChange(row)" v-model="row.checkFlag"></el-checkbox>
        </template>
      </el-table-column>
      <el-table-column label="排序" align="center">
        <template v-slot="{ row }">
          <el-input @change="changeSort(row)" class="borderNone" size="small" type="number" v-model="row.sort"></el-input>
        </template>
      </el-table-column>
      <el-table-column label="卡项图片" align="center">
        <template v-slot="{ row }">
          <el-image style="width: 60px; height: 60px" :src="row.card_item_picture"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="card_item_name" label="服务名称" align="center"></el-table-column>
      <el-table-column label="卡项价格" align="center">
        <template v-slot="{ row }">￥{{ row.card_item_price }}</template>
      </el-table-column>
      <el-table-column label="有效期" align="center">
        <template v-slot="{ row }">
          {{ row.type === 1 ? '永久有效' : row.type === 2 ? `购买后${row.indate}天内有效` : row.type === 3 ? `${getDateformat(row.indate)}前有效` : '' }}
        </template>
      </el-table-column>
      <el-table-column label="上/下架" align="center">
        <template v-slot="{ row }">
          <el-button @click="changeStatus(row)" :type="!row.status ? 'primary' : ''" size="small">{{ row.status ? '下架' : '上架' }}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center">
        <template v-slot="{ row }">
          <el-button type="text" @click="$router.push(`/service/beautyCardServiceInfo?id=${row.id}`)">查看</el-button>
          <el-button type="text" @click="deleteCard([row.id])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="rows" @updatePageNum="updateData">
      <template #batch>
        <div>
          <el-checkbox v-model="allCheck" @change="allCheckChange" label="全选"></el-checkbox>
          <el-select style="margin: 0 20px" size="small" v-model="allSelect">
            <el-option v-for="item in batchList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-button size="small" @click="manyOperation">确定</el-button>
        </div>
      </template>
    </Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate.js';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      ruleForm: {
        card_item_name: '',
        status: 0,
      },
      statusList: [
        { id: 0, name: '全部' },
        { id: 2, name: '上架' },
        { id: 1, name: '下架' },
      ],
      page: 1,
      rows: 10,
      total: 0,
      chooseCardList: [],
      cardList: [],
      allCheck: false,
      allSelect: null,
      batchList: [
        { id: 1, name: '上架' },
        { id: 2, name: '下架' },
        { id: 3, name: '删除' },
      ],
    };
  },
  created() {
    this.getList(1);
  },
  methods: {
    getList(style) {
      if (style) this.page = 1;
      let obj = {
        page: this.page,
        rows: this.rows,
      };
      if (this.ruleForm.card_item_name) {
        obj.card_item_name = this.ruleForm.card_item_name;
      }
      if (this.ruleForm.status) {
        obj.status = this.ruleForm.status;
      }
      this.$axios.post(this.$api.beauty.service.cardList, obj).then(res => {
        if (res.code === 0) {
          this.cardList = res.result.list;
          this.cardList.map(item => {
            this.$set(item, 'checkFlag', false);
          });
          this.chearChooseTime();
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 操作全选
    allCheckChange() {
      if (this.allCheck) {
        this.cardList.map(item => {
          item.checkFlag = true;
          this.chooseCardList.push(item.id);
        });
      } else {
        this.cardList.map(item => {
          item.checkFlag = false;
          this.chooseCardList = [];
        });
      }
    },
    serviceCheckChange(data) {
      if (!data.checkFlag) {
        this.allCheck = false;
        let index = this.chooseCardList.findIndex(item => item == data.id);
        this.chooseCardList.splice(index, 1);
      } else {
        this.chooseCardList.push(data.id);
        if (this.chooseCardList.length === this.cardList.length) this.allCheck = true;
      }
    },
    async manyOperation() {
      if (!this.allSelect) {
        this.$message.warning('请选择要执行的操作');
        return;
      }
      if (!this.chooseCardList.length) {
        this.$message.warning('请选择要执行的卡项');
        return;
      }
      if (this.allSelect === 1 || this.allSelect === 2) {
        let status = this.allSelect === 1 ? 0 : 1;
        let res = await this.upOrDown(this.chooseCardList, status);
        if (res.code === 0) {
          let str = this.allSelect === 1 ? '批量下架成功' : '批量上架成功';
          this.$message.success(str);
          this.getList();
        } else {
          this.$message.warning(res.msg);
        }
      } else {
        this.deleteCard(this.chooseCardList);
      }
    },
    // 改变状态
    async changeStatus(data) {
      let ids = [data.id];
      let status = data.status ? 0 : 1;
      let res = await this.upOrDown(ids, status);
      if (res.code === 0) {
        let str = status === 0 ? '下架成功' : '上架成功';
        this.$message.success(str);
        data.status = status;
      } else {
        this.$message.warning(res.msg);
      }
    },
    // 上架 下架函数
    upOrDown(ids, status) {
      return this.$axios.post(this.$api.beauty.service.cardEditStatus, {
        id: ids,
        status,
      });
    },
    // 清空选中状态
    chearChooseTime() {
      this.chooseCardList = [];
      this.allCheck = false;
    },
    // 更改排序
    changeSort(data) {
      this.$axios
        .post(this.$api.beauty.service.cardEditSort, {
          id: data.id,
          sort: data.sort,
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('更改排序成功');
          } else {
            this.$message.warning(res.msg);
          }
        });
    },
    // 删除卡项
    deleteCard(ids) {
      this.$confirm('此操作将永久删除该卡项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return this.$axios.post(this.$api.beauty.service.cardDel, {
            id: ids,
          });
        })
        .then(res => {
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.getList();
          } else {
            this.$message.warning(res.msg);
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .borderNone {
    /deep/ .el-input__inner {
      border-color: transparent;
      text-align: center;
      &:focus {
        border-color: #409eff;
      }
    }
  }
}
</style>
